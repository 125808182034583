import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { MdOutlineChevronLeft, MdOutlineChevronRight } from 'react-icons/md';
import RoomCard from './RoomCard';

const RoomSlider = ({data}) => {
    return (
        <>
            <Swiper
                slidesPerView={2.5}
                spaceBetween={30}
                navigation={{
                    prevEl: ".arrow-left",
                    nextEl: ".arrow-right"
                }}
                breakpoints={{
                    0:{
                        slidesPerView: 1
                    },
                    450:{
                        slidesPerView: 1.5
                    },
                    800:{
                        slidesPerView: 2.5
                    },
                    1000:{
                        slidesPerView: 2
                    },
                    1500:{
                        slidesPerView: 2.5
                    },
                    1800:{
                        slidesPerView: 3
                    }
                }}
                modules={[Navigation]}
                className="mySwiper"
                loop={true}
            >
                {data?.map((content, index) => {
                    return <SwiperSlide key={index}>{<RoomCard data={content} />}</SwiperSlide>
                })}
                <button className="arrow-left arrow"><MdOutlineChevronLeft /></button>
                <button className="arrow-right arrow"><MdOutlineChevronRight /></button>
            </Swiper>
        </>
    )
}

export default RoomSlider