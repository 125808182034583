import { IoShareSocialOutline } from 'react-icons/io5';
import { BiGitCompare } from 'react-icons/bi';
import { FaRegHeart } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const ServiceCard = ({content}) => {
  return (
    <>
    <div className="service-card my-md-5 my-3">
        <label>-30%</label>
        <img src="./assets/images/service1.png" className="img-fluid w-100" alt="" />
        <div className="service-card-body">
            <h3>{content}</h3>
            <p>Stylish Cafe Chair</p>
            <h4>Rs 2,500 <strike>Rs 3,000</strike></h4>
        </div>
        <div className="service-card-hidden">
            <div className="service-card-action">
                <Link to="#" className="btn cart-btn">Add to Cart</Link>
                <div className="d-flex mt-3 p-3 action-btn">
                    <Link to="#" className="btn"><IoShareSocialOutline /> Share</Link>
                    <Link to="#" className="btn"><BiGitCompare /> Compare</Link>
                    <Link to="#" className="btn"><FaRegHeart /> Like</Link>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default ServiceCard