import { BsArrowRight } from 'react-icons/bs';

const RoomCard = ({data}) => {
    return (
        <>
            <div className="room-card">
                <img src="./assets/images/room.png" className="img-fluid" alt="" />
                <div className="room-card-hidden">
                    <div className="room-content">
                        <div className="position-relative">
                            <span>01 - {data}</span>
                            <h3>Inner Peace</h3>
                            <div className="arrow2">
                                <BsArrowRight />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RoomCard