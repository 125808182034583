import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import SimpleSlider from '../Components/SimpleSlider';
import { MdAddCall } from "react-icons/md";
import RoomSlider from "../Components/RoomSlider";
import InstagramPostEmbed from "../Components/InstagramPostEmbed";
const Home = () => {
  return (
    <>
      <Helmet>
        <title>Home</title>
        <script async src="https://www.instagram.com/embed.js"></script>
      </Helmet>
      <div className="main-banner">
        <img className="banner-img" src='./assets/images/banner.png' alt="Main Banner" />
        <div className="left-side">
          <h2 className="banner-heading">Transform <span className="underline">Your Home</span> <br /> into an Oasis of Luxury</h2>
        </div>
        <div className="right-side">
          <h2 className="banner-heading">eBbuildBazaar's <br /> Luxury Renovation <br /> Services</h2>
          <p className="text-white mb-0">Premium renovation services for gourmand homeowners!</p>
          <div>
            <Link className="btn main-btn">Explore Our Services <svg width="35" height="16" viewBox="0 0 57 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 7C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9V7ZM56.7071 8.70711C57.0976 8.31658 57.0976 7.68342 56.7071 7.29289L50.3431 0.928932C49.9526 0.538408 49.3195 0.538408 48.9289 0.928932C48.5384 1.31946 48.5384 1.95262 48.9289 2.34315L54.5858 8L48.9289 13.6569C48.5384 14.0474 48.5384 14.6805 48.9289 15.0711C49.3195 15.4616 49.9526 15.4616 50.3431 15.0711L56.7071 8.70711ZM1 9H56V7H1V9Z" /></svg></Link>
          </div>
        </div>
      </div>

      <section className="my-md-5 my-3 py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 order-2 order-lg-1 my-3">
              <div className='img-box'>
                <img src='./assets/images/about-img.png' alt="" className="img-fluid " />
              </div>
            </div>
            <div className="col-lg-6 about-content order-1 order-lg-2 my-3">
              <h1 className="main-heading">Unlock the Epitome Of <u>Elegance</u> & Of <u>Refinement</u></h1>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor fugiat consectetur aliquid voluptatem consequatur quam repellendus esse dolorem ad numquam.</p>
              <div>
                <Link className="btn main-btn2">View Gallery
                  <svg width="59" height="18" viewBox="0 0 99 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.27832 8.07596C0.628162 8.07596 0.101105 8.60302 0.101105 9.25317C0.101105 9.90333 0.628162 10.4304 1.27832 10.4304L1.27832 8.07596ZM98.6424 10.0856C99.1021 9.62586 99.1021 8.88049 98.6424 8.42076L91.1506 0.929006C90.6909 0.469275 89.9455 0.469275 89.4858 0.929006C89.0261 1.38874 89.0261 2.13411 89.4858 2.59384L96.1451 9.25317L89.4858 15.9125C89.0261 16.3722 89.0261 17.1176 89.4858 17.5773C89.9455 18.0371 90.6909 18.0371 91.1506 17.5773L98.6424 10.0856ZM1.27832 10.4304L97.81 10.4304V8.07596L1.27832 8.07596L1.27832 10.4304Z" />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="my-md-5 my-3 py-3">
        <div className="container">
          <h1 className="main-heading mb-4 text-center">Our Services</h1>
          <SimpleSlider data={['Slide1', 'Slide2', 'Slide3', 'Slide4', 'Slide5']} />
        </div>
      </section>


      <section className="my-md-5 my-3 py-3">
        <div className="container">
          <div className="bg-design">
            <div className="row align-items-center">
              <div className="col-lg-4 my-3">
                <h4 className="heading">50+ Beautiful room inspirations</h4>
                <p className="para-clr">Our designers already made a lot of beautiful prototipe of rooms that inspire you</p>
                <div className="d-flex gap-2">
                  <button className="btn btn-brown rounded-full"><MdAddCall /></button>
                  <button className="btn btn-brown">Request A Callback</button>
                </div>
              </div>
              <div className="col-lg-8 my-3">
                <RoomSlider data={['Slide1', 'Slide2', 'Slide3', 'Slide4', 'Slide5']} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <InstagramPostEmbed />
    </>
  )
}

export default Home;