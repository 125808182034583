import { IoMdSearch } from 'react-icons/io';
import { FaCaretDown, FaRegUser } from 'react-icons/fa';
import { IoHomeOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <>
      <header className="site-header">
        <div className="pt-2 pb-2 search-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-6 col-xs-6 col-8">
                <div className="logo-img d-flex justify-content-between">
                  <Link to="#"><img src="./assets/images/logo.png" alt="" /></Link>
                </div>
              </div>
              <div className="col-4 text-end align-self-center d-md-none">
                <i className="fa fa-bars fs-3"></i>
              </div>
              <div className="col-md-5 col-sm-5 col-xs-5 lookingfor d-none d-md-block">
                <form method="GET">
                  <div className="search-box">
                    <div className="select-box custom-select1">
                      <div className="select-selected">Product</div>
                    </div>
                    <div className="select-search">
                      <input type="search" id="searchid" name="keyword" className="form-control" placeholder="What are you looking for.." />

                    </div>
                    <div className="seach-btn">
                      <button className="btn"><IoMdSearch className='fs-5' /></button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-md-4 d-none d-md-block">
                <div className="right-menu">
                  <ul style={{ position: "relative" }}>
                    <li id="cart_items" className="flot-right getquote tooltip1">
                      <Link to="#">
                        <i className="fa fa-cart-arrow-down" aria-hidden="true"></i>
                        <span className="cart-count">0</span>
                        <label className="showcatlist">₹</label> <b className="cart-amount">00</b>
                      </Link>
                    </li>
                    <li className="flot-right getquote getquote-signs d-none d-xl-block">
                      <Link to="#" id="SignInlogin" className="SignInlogin">
                        <FaRegUser />
                        Login/SignUp
                      </Link>
                    </li>
                    <li id="service_cart_items" className="flot-right getquote tooltip1">
                      <Link className="">
                        <i className="fa fa-shopping-basket" aria-hidden="true"></i>
                        <span className="service_cart-count">0</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-1-top d-none d-lg-block">
          <div className="container1">
            <div className="middelHeader">
              <div className="container">
                <nav>
                  <div className="row">
                    <div className="col-md-12 col-xs-12">
                      <div className="collapseNav top-headers">
                        <ul className="first-ul">
                        <li className="shop">
                          <Link to="#" id="shopid">Shop By Department <FaCaretDown /></Link>
                          <div className="top_ul pro_ul">
                              <ul className="departmentdks">
                                <li>
                                  <Link to="#"
                                    className="firstnav proservicelink">
                                    <span className="img000"><img
                                      src="https://stagedev.ebuildbazaar.in/public/uploads/all/8sYAcc1eyNom0evOJwVwIltTB54x6FYrKkD8XY0i.webp"
                                      className="NavThumbnail fl " alt="Construction Equipments" /></span>
                                    <span className="spand-line">Construction Equipments
                                    </span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="#"
                                    className="firstnav proservicelink">
                                    <span className="img000"><img
                                      src="https://stagedev.ebuildbazaar.in/public/uploads/all/si61eXoBsxBGQX0YM7hJYcSm6y5YHsm7bcIjNqHA.webp"
                                      className="NavThumbnail fl " alt="Turnkey Services" /></span>
                                    <span className="spand-line">Turnkey Services<i className="fa fa-angle-right"
                                      aria-hidden="true"></i></span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="#"
                                    className="firstnav proservicelink">
                                    <span className="img000"><img
                                      src="https://stagedev.ebuildbazaar.in/public/uploads/all/5mG8yGS5bj563OVxGgGZ1TorRGUEr5xP3BnSbT7a.webp"
                                      className="NavThumbnail fl " alt="Architects" /></span>
                                    <span className="spand-line">Architects<i className="fa fa-angle-right"
                                      aria-hidden="true"></i></span>
                                  </Link>
                                </li>

                                <li>
                                  <Link to="#"
                                    className="firstnav proservicelink">
                                    <span className="img000"><img
                                      src="https://stagedev.ebuildbazaar.in/public/uploads/all/ZOkxIS2XVxKvDvpM13ZksJFBtC7UJ61ZvTCozbh8.webp"
                                      className="NavThumbnail fl " alt="Interior Designers" /></span>
                                    <span className="spand-line">Interior Designers<i className="fa fa-angle-right"
                                      aria-hidden="true"></i></span>
                                  </Link>
                                </li>

                                <li>
                                  <Link to="#"
                                    className="firstnav proservicelink">
                                    <span className="img000"><img
                                      src="https://stagedev.ebuildbazaar.in/public/uploads/all/UAPC8bfV4ib6ADoFptuSpeBeM9kEQQwlWf529XjF.webp"
                                      className="NavThumbnail fl " alt="Vastu" /></span>
                                    <span className="spand-line">Vastu<i className="fa fa-angle-right"
                                      aria-hidden="true"></i></span>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                        </li>
                        <li className="more_tabs menulocationds1">
                            <Link to="#"><IoHomeOutline className='fs-5' /></Link>
                          </li>
                          <li className="menulocationds proservices"><Link to="#"
                            className="proserviceid">Professional Services <FaCaretDown /></Link>
                            <div className="top_ul pro_ul">
                              <ul className="departmentdks">
                                <li>
                                  <Link to="#"
                                    className="firstnav proservicelink">
                                    <span className="img000"><img
                                      src="https://stagedev.ebuildbazaar.in/public/uploads/all/8sYAcc1eyNom0evOJwVwIltTB54x6FYrKkD8XY0i.webp"
                                      className="NavThumbnail fl " alt="Construction Equipments" /></span>
                                    <span className="spand-line">Construction Equipments
                                    </span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="#"
                                    className="firstnav proservicelink">
                                    <span className="img000"><img
                                      src="https://stagedev.ebuildbazaar.in/public/uploads/all/si61eXoBsxBGQX0YM7hJYcSm6y5YHsm7bcIjNqHA.webp"
                                      className="NavThumbnail fl " alt="Turnkey Services" /></span>
                                    <span className="spand-line">Turnkey Services<i className="fa fa-angle-right"
                                      aria-hidden="true"></i></span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="#"
                                    className="firstnav proservicelink">
                                    <span className="img000"><img
                                      src="https://stagedev.ebuildbazaar.in/public/uploads/all/5mG8yGS5bj563OVxGgGZ1TorRGUEr5xP3BnSbT7a.webp"
                                      className="NavThumbnail fl " alt="Architects" /></span>
                                    <span className="spand-line">Architects<i className="fa fa-angle-right"
                                      aria-hidden="true"></i></span>
                                  </Link>
                                </li>

                                <li>
                                  <Link to="#"
                                    className="firstnav proservicelink">
                                    <span className="img000"><img
                                      src="https://stagedev.ebuildbazaar.in/public/uploads/all/ZOkxIS2XVxKvDvpM13ZksJFBtC7UJ61ZvTCozbh8.webp"
                                      className="NavThumbnail fl " alt="Interior Designers" /></span>
                                    <span className="spand-line">Interior Designers<i className="fa fa-angle-right"
                                      aria-hidden="true"></i></span>
                                  </Link>
                                </li>

                                <li>
                                  <Link to="#"
                                    className="firstnav proservicelink">
                                    <span className="img000"><img
                                      src="https://stagedev.ebuildbazaar.in/public/uploads/all/UAPC8bfV4ib6ADoFptuSpeBeM9kEQQwlWf529XjF.webp"
                                      className="NavThumbnail fl " alt="Vastu" /></span>
                                    <span className="spand-line">Vastu<i className="fa fa-angle-right"
                                      aria-hidden="true"></i></span>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li className="menulocationds menulocationds1 com ">
                            <Link to="#s">About Us</Link>
                          </li>
                          <li className="more_tabs menulocationds1">
                            <Link to="#">Career</Link>
                          </li>
                          <li className="more_tabs menulocationds1">
                            <Link to="#"> Help Center </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div >
      </header >
    </>
  )
}

export default Header