import { Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import NotFound from './Pages/NotFound';
import Layout from './Layout/Layout';

function App() {

  return (
    <>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route path='*' element={<NotFound />} />
          <Route path='/' element={<Home />} />
        </Route>
      </Routes>
    </>
  )
}
export default App