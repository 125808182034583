import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import ServiceCard from './ServiceCard';
import { MdOutlineChevronLeft, MdOutlineChevronRight } from 'react-icons/md';

const SimpleSlider = ({data}) => {
    return (
        <>
            <Swiper
                slidesPerView={3.5}
                spaceBetween={30}
                navigation={{
                    prevEl:".arrow-left",
                    nextEl:".arrow-right"
                }}
                breakpoints={{
                    0:{
                        slidesPerView: 1
                    },
                    450:{
                        slidesPerView: 1.5
                    },
                    600:{
                        slidesPerView: 2
                    },
                    800:{
                        slidesPerView: 2.5
                    },
                    1000:{
                        slidesPerView: 3
                    },
                    1200:{
                        slidesPerView: 3.5
                    },
                    1500:{
                        slidesPerView: 4.5
                    }
                }}
                modules={[Navigation]}
                className="mySwiper"
                loop={true}
            >
                {data?.map((content,index)=>{
                    return <SwiperSlide key={index}>{<ServiceCard content={content} />}</SwiperSlide>
                })}
                <button className="arrow-left arrow"><MdOutlineChevronLeft /></button>
                <button className="arrow-right arrow"><MdOutlineChevronRight /></button>
            </Swiper>
        </>
    )
}

export default SimpleSlider