import { Link } from "react-router-dom"

const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer-wrapper">
          <div className="footer-area">
            <div className="container">
              <div className="row">
                <div className="col-md-2">
                  <h4 className="footer-heading">ONLINE PRODUCTS</h4>
                  <div className="mb-2"><Link to="#" className="text-white"><i className="fa fa-angle-double-right"></i> Home</Link></div>
                  <div className="mb-2"><Link to="#" className="text-white"><i className="fa fa-angle-double-right"></i> About Us</Link></div>
                  <div className="mb-2"><Link to="#" className="text-white"><i className="fa fa-angle-double-right"></i> Contact Us</Link></div>
                  <div className="mb-2"><Link to="#" className="text-white"><i className="fa fa-angle-double-right"></i> Blogs</Link></div>
                  <div className="mb-2"><Link to="#" className="text-white"><i className="fa fa-angle-double-right"></i> Sitemaps</Link></div>
                </div>
                <div className="col-md-2">
                  <h4 className="footer-heading">ONLINE SERVICES</h4>
                  <div className="mb-2"><Link to="#" className="text-white"><i className="fa fa-angle-double-right"></i> Home</Link></div>
                  <div className="mb-2"><Link to="#" className="text-white"><i className="fa fa-angle-double-right"></i> About Us</Link></div>
                  <div className="mb-2"><Link to="#" className="text-white"><i className="fa fa-angle-double-right"></i> Contact Us</Link></div>
                  <div className="mb-2"><Link to="#" className="text-white"><i className="fa fa-angle-double-right"></i> Blogs</Link></div>
                  <div className="mb-2"><Link to="#" className="text-white"><i className="fa fa-angle-double-right"></i> Sitemaps</Link></div>
                </div>
                <div className="col-md-2">
                  <h4 className="footer-heading" style={{visibility: 'hidden'}}>ONLINE SERVICES</h4>
                  <div className="mb-2"><Link to="#" className="text-white"><i className="fa fa-angle-double-right"></i> Home</Link></div>
                  <div className="mb-2"><Link to="#" className="text-white"><i className="fa fa-angle-double-right"></i> About Us</Link></div>
                  <div className="mb-2"><Link to="#" className="text-white"><i className="fa fa-angle-double-right"></i> Contact Us</Link></div>
                  <div className="mb-2"><Link to="#" className="text-white"><i className="fa fa-angle-double-right"></i> Blogs</Link></div>
                  <div className="mb-2"><Link to="#" className="text-white"><i className="fa fa-angle-double-right"></i> Sitemaps</Link></div>
                </div>
                <div className="col-md-3">
                  <h4 className="footer-heading">USEFUL LINKS</h4>
                  <div className="mb-2"><Link to="#" className="text-white"><i className="fa fa-angle-double-right"></i> Collections</Link></div>
                  <div className="mb-2"><Link to="#" className="text-white"><i className="fa fa-angle-double-right"></i> Trending Products</Link></div>
                  <div className="mb-2"><Link to="#" className="text-white"><i className="fa fa-angle-double-right"></i> New Arrivals Products</Link></div>
                  <div className="mb-2"><Link to="#" className="text-white"><i className="fa fa-angle-double-right"></i> Featured Products</Link></div>
                  <div className="mb-2"><Link to="#" className="text-white"><i className="fa fa-angle-double-right"></i> Cart</Link></div>
                </div>
                <div className="col-md-3">
                  <img src="./assets/images/logo.png" alt="" />
                  <h4 className="footer-heading">CONTACT INFO</h4>
                  <div className="mb-2">
                    <p>
                      <i className="fa fa-map-marker"></i> #444, some main road, some area, some street, bangalore, india - 560077
                    </p>
                  </div>
                  <div className="mb-2">
                    <Link to="#" className="text-white">
                      <i className="fa fa-phone"></i> +91 888-XXX-XXXX
                    </Link>
                  </div>
                  <div className="mb-2">
                    <Link to="#" className="text-white">
                      <i className="fa fa-envelope"></i> fundaofwebit@gmail.com
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright-area">
            <div className="container">
              <div className="row position-relative">
                <div className="col-md-12">
                  <p className=""> Copyright © 2021-2022 ebuildbazaar. All rights reserved | <Link to="#">Policy</Link> | <Link to="#">Help Center</Link> | <Link to="#">Become a Seller</Link> | <Link to="#">Advertise</Link> | <Link to="#">My Account</Link> | <Link to="#">Disclaimer</Link> </p>
                </div>
                <div className="col-md-3">
                  <div className="social-media">
                    <Link to="#"><i className="fa fa-facebook"></i></Link>
                    <Link to="#"><i className="fa fa-twitter"></i></Link>
                    <Link to="#"><i className="fa fa-instagram"></i></Link>
                    <Link to="#"><i className="fa fa-youtube"></i></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer